import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Item from "../components/Item"
import Layout from "../components/Layout"
import SEO from "../components/SEO"

import "../utils/image"

export const query = graphql`
  query {
    alimentation: file(relativePath: { eq: "chevres/C4.jpg" }) {
      ...squareImage
    }
    reproduction: file(relativePath: { eq: "chevres/C7.jpg" }) {
      ...squareImage
    }
    traite: file(relativePath: { eq: "traite.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 900, maxWidth: 900) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Chevrerie = () => {
  const description = `Notre troupeau est composé de 140 chèvres de race Alpine, d’une trentaine de chevrettes élevées pour assurer le renouvellement du troupeau et de cinq boucs.`

  return (
    <Layout>
      <SEO title="La Chèvrerie" description={description} />
      <div style={{ marginBottom: "1.8rem" }}>
        <h1 style={{ textAlign: "center", textTransform: "uppercase" }}>
          La Chèvrerie
        </h1>
        <p style={{ textAlign: "justify" }}>{description}</p>
      </div>
      <StaticQuery
        query={query}
        render={({ alimentation, reproduction, traite }) => (
          <>
            <Item
              image={alimentation}
              title="L'alimentation"
              text={
                <p>
                  Les chèvres sont nourries principalement avec de l’herbe
                  d’avril à novembre lorsque les conditions climatiques le
                  permettent et du foin, qui est de l’herbe séchée.
                  L’alimentation est complétée avec un mélange de céréales
                  produit sur la ferme qui comprend de l’orge, de l’avoine, du
                  triticale, du pois et de la féverolle. Les chèvres mangent
                  également de petites quantités de paille de céréale que l’on
                  leur met à disposition. Produire la quasi-totalité de
                  l’alimentation sur la ferme nous permet d’assurer la
                  production d’un lait de qualité.
                </p>
              }
            ></Item>
            <Item
              image={reproduction}
              title="La reproduction"
              text={
                <p>
                  Les chèvres sont naturellement en chaleur à partir de fin
                  aout. Les chaleurs sont déclenchées par la diminution de la
                  longueur des journées. Nous mettons donc les boucs avec les
                  chèvres jusqu’en début novembre. La durée de gestation d’une
                  chèvre est de 5 mois. La période des mises-bas commence donc
                  en début mars. Les chèvres mettent bas en moyenne 1,5
                  chevreaux par an.
                </p>
              }
              left
            ></Item>
            <Item
              image={traite}
              title="La traite"
              text={
                <p>
                  Les chèvres sont traites deux fois par jour environ 300 jours
                  par an à partir de la mise-bas. Deux mois avant la mise bas,
                  les chèvres ne sont plus traites pour leur permettre de
                  reconstituer des réserves. La production moyenne des chèvres
                  est d’environ 700 litres par an.
                </p>
              }
            ></Item>
          </>
        )}
      />
    </Layout>
  )
}

export default Chevrerie
